<template>
  <div class="commercial-step">
    <div class="row">
      <div class="col-md-4 mob-stepper">
        <div
          class="package-order-info"
          :class="`package-order-info${$route.params.id}`"
        >
          <ul class="order-status">
            <li :class="[index == 0 || index > 0 ? `active` : ``]">
              <span>1</span>
              <div>
                {{
                  this.$i18n.locale == `ar`
                    ? `أضف معلوماتك الشخصية`
                    : `add your personal information`
                }}
              </div>
            </li>
            <li class="gray" :class="[index > 0 ? `active` : ``]">
              <span>2</span>
              <div>
                {{
                  this.$i18n.locale == `ar`
                    ? ` حدد موقع العمل الخاص بك`
                    : `Determine your work
                                location`
                }}
              </div>
            </li>
            <li class="gray" :class="[index > 1 ? `active` : ``]">
              <span>3</span>
              <div>
                {{
                  this.$i18n.locale == `ar`
                    ? `الإجابة على الأسئلة`
                    : `answer the questions`
                }}
              </div>
            </li>
            <li class="gray" :class="[index > 2 ? `active` : ``]">
              <span>4</span>
              <div>
                {{
                  this.$i18n.locale == `ar`
                    ? `أتمم طلبك`
                    : `Complete your order`
                }}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-8 pr-4 pl-4">
        <Form
          class="commercial-form"
          @submit="onSubmit"
          :validation-schema="currentSchema"
        >
          <swiper
            :slides-per-view="1"
            class="swiper"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
            :autoHeight="true"
            :scrollbar="{ draggable: true }"
            :space-between="10"
            :allowTouchMove="false"
            :navigation="{
              enabled: true,
              nextEl: `.myNext`,
              prevEl: `.myPrev`,
            }"
          >
            <swiper-slide class="mob">
              <div class="gray-title">
                <div class="row align-items-center">
                  <div class="col-sm-2 p-0 col-4">
                    <img
                      class="w-100"
                      src="@/assets/img/commercial-franchise/slider1.png"
                      alt="slider"
                    />
                  </div>
                  <div class="col-sm-10 col-8">
                    <h4>
                      {{
                        this.$i18n.locale == `ar`
                          ? `أضف معلوماتك الشخصية`
                          : `add your personal
                                            information`
                      }}
                    </h4>
                  </div>
                </div>
              </div>
              <div class="row itemm pt-4 pb-4">
                <div class="col-md-6 mt-3">
                  <Field
                  @keydown="handleKeydown"
                    :placeholder="$t(`Full_name`)"
                    name="name"
                    type="text"
                    v-model="name"
                  />
                  <ErrorMessage name="name" class="commercial-form-error" />
                </div>
                <div class="col-md-6 mt-3">
                  <Field
                    id="phone"
                    name="phone"
                    type="tel"
                    v-model="phone"
                    :placeholder="$t(`PhoneNumber`)"
                  />
                  <ErrorMessage name="phone" class="commercial-form-error mt-1 mb-1" />
                  <span id="output" class="commercial-form-error mt-1 mb-1">
                  </span>
                </div>
                <div class="col-md-6 mt-3">
                  <Field
                  @keydown="handleKeydown"
                    :placeholder="$t(`Email`)"
                    name="email"
                    type="text"
                    v-model="email"
                  />
                  <ErrorMessage name="email" class="commercial-form-error" />
                </div>

                <div class="col-md-6 mt-3">
                  <Field
                  @keydown="handleKeydown"
                    :placeholder="$t(`qualification`)"
                    type="text"
                    v-model="educational_qualification"
                    name="educational_qualification"
                  />
                  <ErrorMessage
                    name="educational_qualification"
                    class="commercial-form-error"
                  />
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="gray-title">
                <div class="row align-items-center">
                  <div class="col-sm-2 col-4">
                    <img src="@/assets/img/commercial-franchise/slider2.png" alt="slider"/>
                  </div>
                  <div class="col-sm-10 col-8">
                    <h4>
                      {{
                        this.$i18n.locale == `ar`
                          ? ` حدد موقع العمل الخاص بك`
                          : `Determine your
                                            work location`
                      }}
                    </h4>
                  </div>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-md-6 mt-3">
                                    <Field @keydown="handleKeydown" name="city" v-slot="{ field }">
                                        <multiselect :searchable="false" v-model="value2" @select="PickId2" v-bind="field"
                                            :options="cities"
                                            open-direction="bottom"
                                            :placeholder="this.$i18n.locale == 'ar' ? 'اختر المدينة' : 'Choose the city'"
                                            label="name" track-by="name" selectLabel=""
                                            :selectedLabel="this.$i18n.locale == 'ar' ? 'المحدد' : 'Selected'"
                                            :deselectLabel="this.$i18n.locale == 'ar' ? 'اضغط للإزالة' : 'Press enter to remove'">
                                        </multiselect>
                                    </Field>
                                    <ErrorMessage name="city" class="commercial-form-error" />
                                </div>
                <!-- <h5>
                  {{
                    this.$i18n.locale == `ar`
                      ? `او يمكنك التحديد على الخريطة`
                      : `Or you can select
                                    on the map`
                  }}
                </h5> -->

                <!-- <p>city: {{ this.city }}</p>
                <Field
                @keydown="handleKeydown"
                  v-model="city"
                  id="city"
                  type="hidden"
                  name="city"
                  value=""
                />
                <ErrorMessage name="city" class="commercial-form-error" /> -->

                <!-- <div class="col-md-12"> -->
                  <!-- <GoogleMap @my-event="handleArrayFromChild" /> -->
                  <!-- <div class="d-flex" style="opacity: 0; height: 0;"> -->
                  <!-- <Field v-model="latitude" id="latitude" type="text" name="latitude" />
                                <ErrorMessage name="latitude" class="commercial-form-error" />

                                <Field v-model="longitude" id="longitude" type="text" name="longitude" />
                                <ErrorMessage name="longitude" class="commercial-form-error" /> -->

                  <!-- </div> -->
                <!-- </div> -->
              </div>
            </swiper-slide>
            <swiper-slide class="mob-qes" style="min-height: 1050px">
              <div class="gray-title">
                <div class="row align-items-center">
                  <div class="col-sm-2 col-4">
                    <img src="@/assets/img/commercial-franchise/slider3.png" alt="slider"/>
                  </div>
                  <div class="col-sm-10 col-8">
                    <h4>
                      {{
                        this.$i18n.locale == `ar`
                          ? `الإجابة على الأسئلة`
                          : `answer the
                                            questions`
                      }}
                    </h4>
                  </div>
                </div>
              </div>
              <div class="row itemm pt-4 pb-4">
                <div class="col-md-12 mb-4">
                  <label>{{ $t("q_1") }}</label>
                  <Field
                  @keydown="handleKeydown"
                    v-model="motivation_to_work"
                    name="motivation_to_work"
                    :placeholder="$t(`answer`)"
                    as="textarea"
                    class="p-3 mt-2"
                    style="height: 115px"
                  >
                  </Field>
                  <ErrorMessage
                    name="motivation_to_work"
                    class="commercial-form-error"
                  />
                </div>
                <div class="col-md-12 mb-4">
                  <label>{{ $t("q_2") }}</label>
                  <Field
                  @keydown="handleKeydown"
                    v-model="previous_projects"
                    name="previous_projects"
                    :placeholder="$t(`answer`)"
                    as="textarea"
                    class="p-3 mt-2"
                    style="height: 115px"
                  >
                  </Field>
                  <ErrorMessage
                    name="previous_projects"
                    class="commercial-form-error"
                  />
                </div>
                <div class="col-md-12 mb-4">
                  <label>{{ $t("q_3") }}</label>
                  <Field
                  @keydown="handleKeydown"
                    v-model="existing_franchises"
                    name="existing_franchises"
                    :placeholder="$t(`answer`)"
                    as="textarea"
                    class="p-3 mt-2"
                    style="height: 115px"
                  >
                  </Field>
                  <ErrorMessage
                    name="existing_franchises"
                    class="commercial-form-error"
                  />
                </div>
                <div class="col-md-12 mb-4">
                  <label>{{ $t("q_4") }}</label>
                  <Field
                  @keydown="handleKeydown"
                    v-model="experience_in_food_beverage"
                    name="experience_in_food_beverage"
                    :placeholder="$t(`answer`)"
                    as="textarea"
                    class="p-3 mt-2"
                    style="height: 115px"
                  >
                  </Field>
                  <ErrorMessage
                    name="experience_in_food_beverage"
                    class="commercial-form-error"
                  />
                </div>
                <div class="col-md-12 mb-4">
                  <label>{{ $t("q_5") }}</label>
                  <Field
                  @keydown="handleKeydown"
                    v-model="investment_amount"
                    :placeholder="$t(`answer`)"
                    name="investment_amount"
                    type="number"
                  />
                  <ErrorMessage
                    name="investment_amount"
                    class="commercial-form-error"
                  />
                </div>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="gray-title">
                <div class="row align-items-center">
                  <div class="col-sm-2 col-4">
                    <img src="@/assets/img/commercial-franchise/slider4.png" alt="slider"/>
                  </div>
                  <div class="col-sm-10 col-8">
                    <h4>
                      {{
                        this.$i18n.locale == `ar`
                          ? `أتمم طلبك`
                          : `Complete your order`
                      }}
                    </h4>
                  </div>
                </div>
              </div>
              <div class="row pt-4 pb-4">
                <div class="col-md-12 mt-3">
                  <h5>{{ $t("Personal_data") }}</h5>
                  <div class="row">
                    <div class="col-4 key">{{ $t("FullName") }}</div>
                    <div class="col-4 value">{{ this.name }}</div>
                    <div class="col-4"></div>
                  </div>
                  <div class="row">
                    <div class="col-4 key">{{ $t("PhoneNumber") }}</div>
                    <div class="col-4 value">{{ this.phone }}</div>
                    <div class="col-4"></div>
                  </div>
                  <div class="row">
                    <div class="col-4 key">{{ $t("Email") }}</div>
                    <div class="col-4 value">{{ this.email }}</div>
                    <div class="col-4"></div>
                  </div>
                  <div class="row">
                    <div class="col-4 key">{{ $t("mcity") }}</div>
                    <div class="col-4 value">{{ this.city }}</div>
                    <div class="col-4"></div>
                  </div>
                </div>
                <div class="col-md-12 mt-3">
                  <label>{{ $t("q_1") }}</label>
                  <p>{{ this.motivation_to_work }}</p>
                </div>
                <div class="col-md-12 mt-3">
                  <label>{{ $t("q_2") }}</label>
                  <p>{{ this.previous_projects }}</p>
                </div>
                <div class="col-md-12 mt-3">
                  <label>{{ $t("q_3") }}</label>
                  <p>{{ this.existing_franchises }}</p>
                </div>
                <div class="col-md-12 mt-3">
                  <label>{{ $t("q_4") }}</label>
                  <p>{{ this.experience_in_food_beverage }}</p>
                </div>
                <div class="col-md-12 mt-3">
                  <label>{{ $t("q_5") }}</label>
                  <p>{{ this.investment_amount }}</p>
                </div>
              </div>
            </swiper-slide>
          </swiper>

          <div
            class="d-flex navigation-button mt-3 mb-4"
            :class="[
              index === 0
                ? ` justify-content-flex-end`
                : `justify-content-between`,
            ]"
          >
            <button
            @click.prevent="handlePrevClick"
              class="next-step myPrev"
              :class="[index === 0 ? `d-none` : ``]"
            >
              {{ this.$i18n.locale == `ar` ? `الخطوة السابقة` : `Previous` }}
            </button>
            <template v-if="!$store.state.Token">
              <button
                @click="Checklogin()"
                v-if="!showLoginButton"
                class="next-step"
              >
                <span>
                  {{ $t("Next Step") }}
                </span>
              </button>
              <div v-else>
                <span class="alert alert-warning m-3">
                  {{
                    this.$i18n.locale == `ar`
                      ? `يجب القيام بتسجيل الدخول لإتمام الطلب`
                      : `You must be login for continues`
                  }}
                </span>
                <button @click="redirectToLogin" class="next-step">
                  {{ $t("Login") }}
                </button>
              </div>
            </template>
            <div v-else>
              <button type="submit" v-if="index != 3" class="next-step">
                <span>
                  {{ $t("Next Step") }}
                </span>
              </button>

              <button
                type="submit"
                id="but-submit"
                class="next-step myNext"
                v-else
              >
                <span>
                  {{ $t("Submit Order") }}
                </span>
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import { defineAsyncComponent } from "vue";
import axios from "axios";
import { notify } from "@kyvg/vue3-notification";
import * as yup from "yup";
import { configure } from "vee-validate";
import intlTelInput from "../../intl-tel-input.js";
import Cookie from "cookie-universal";
import Multiselect from 'vue-multiselect';

const cookie = Cookie();
const validName = (value) => value && value.trim().length > 0 && !value.startsWith(" ")

export default {
  data() {
    return {
      index: 0,
      swiper: null,
      commercialData: [],
      latitude: null,
      longitude: null,
      name: "",
      email: "",
      phone: null,
      city: "",
      educational_qualification: "",
      motivation_to_work: "",
      previous_projects: "",
      existing_franchises: "",
      experience_in_food_beverage: "",
      investment_amount: "",
      FullNumber: "",
      Status: false,
      showLoginButton: false,
      cities: [],

    };
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    Multiselect
    // GoogleMap: defineAsyncComponent(() =>
    //   import("@/components/CommercialFranchise/googleMap.vue")
    // ),
  },
  setup() {
    configure({
      validateOnInput: true,
    });
    const schema = [
      yup.object({
        name: yup
          .string()
          .required(
            cookie.get("lang") == "ar" ? "حقل الاسم مطلوب" : "Name is required"
          )
          .test("validName", cookie.get('lang') === 'ar' ? 'تنسيق الاسم غير صالح.':'Invalid name format.', validName),
        phone: yup
          .string()
          .required(
            cookie.get("lang") == "ar" ? "حقل رقم الهاتف مطلوب " : "phone number is required"
          ),
        email: yup
          .string()
          .required(
            cookie.get("lang") == "ar"
              ? "حقل البريد الإلكتروني مطلوب"
              : "email required"
          )
          .email(
            cookie.get("lang") == "ar"
              ? " البريد الإلكتروني غير مطابق"
              : "email dose not match"
          ),
        educational_qualification: yup
          .string()
          .required(
            cookie.get("lang") == "ar"
              ? "المؤهل العلمي مطلوب"
              : "Educational qualification is required"
          )
          .test("validName", cookie.get('lang') === 'ar' ? 'تنسيق المؤهل العلمي غير صالح.':'Invalid educational qualification format.', validName),
      }),
      yup.object({
        // latitude: yup.string().required(),
        // longitude: yup.string().required(),
        city: yup
          .object()
          .required(
            cookie.get("lang") == "ar"
              ? "اختيار المدينة مطلوب"
              : "City field is required"
          ),
      }),
      yup.object({
        motivation_to_work: yup
          .string()
          .required(
            cookie.get("lang") == "ar"
              ? "الحقل مطلوب"
              : "required field"
          ).min(
          3,
          cookie.get("lang") == "ar"
            ? "يجب أن يكون الحقل من 3 أحرف على الأقل"
            : "name must be at least 3 characters"
        )
          .test("validName", cookie.get('lang') === 'ar' ? 'تنسيق الحقل غير صالح.':'Invalid field format.', validName),

        previous_projects: yup
          .string()
          .required(
            cookie.get("lang") == "ar"
              ? "الحقل مطلوب"
              : "required field"
          ).min(
          3,
          cookie.get("lang") == "ar"
            ? "يجب أن يكون الحقل من 3 أحرف على الأقل"
            : "name must be at least 3 characters"
        )
          .test("validName", cookie.get('lang') === 'ar' ? 'تنسيق الحقل غير صالح.':'Invalid field format.', validName),

        existing_franchises: yup
          .string()
          .required(
            cookie.get("lang") == "ar"
              ? "الحقل مطلوب"
              : "required field"
          ).min(
          3,
          cookie.get("lang") == "ar"
            ? "يجب أن يكون الحقل من 3 أحرف على الأقل"
            : "name must be at least 3 characters"
        )
          .test("validName", cookie.get('lang') === 'ar' ? 'تنسيق الحقل غير صالح.':'Invalid field format.', validName),

        experience_in_food_beverage: yup
          .string()
          .required(
            cookie.get("lang") == "ar"
              ? "الحقل مطلوب"
              : "required field"
          ).min(
          3,
          cookie.get("lang") == "ar"
            ? "يجب أن يكون الحقل من 3 أحرف على الأقل"
            : "name must be at least 3 characters"
        )
          .test("validName", cookie.get('lang') === 'ar' ? 'تنسيق الحقل غير صالح.':'Invalid field format.', validName),

        investment_amount: yup
        .number()
    .typeError(
      cookie.get("lang") === "ar"
        ? "الحقل مطلوب، يجب إدخال رقم"
        : "required field, Enter a number"
    )
    .required(
      cookie.get("lang") === "ar"
        ? "الحقل مطلوب"
        : "required field"
    )
    .min(100,
      cookie.get("lang") === "ar"
        ? "يجب أن يكون المبلغ 100 أو أكثر"
        : "amount must be at least 100"
    )

      }),
    ];
    return {
      schema,
    };
  },
  computed: {
    currentSchema() {
      return this.schema[this.index];
    },
  },
  methods: {

    handlePrevClick() {
            this.index = this.index - 1
        },
    handleKeydown(event) {
      if (event.key === 'Tab') {
        event.preventDefault(); // Prevent the default tab action
      }
    },
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    onSlideChange() {
      this.index = this.swiper.activeIndex;
    },
       PickId2() {
            this.city = this.value2.id;
        },
    Checklogin() {
      notify({
        type: "warning",
        text:
          this.$i18n.locale == "ar"
            ? "رجاء قم بتسجيل الدخول لإتمام طلبك"
            : "Please login to complete the form",
      });
      this.showLoginButton = true;
    },
    redirectToLogin() {
      this.$router.push("/login");
    },
    async onSubmit(values) {
      if (this.index == 0) {
        if (this.FullNumber) {
          this.index++;
          this.swiper.slideTo(this.index);
        }
      }
      if (this.index == 1) {

        if (this.city) {
          this.index++;
          this.swiper.slideTo(this.index);
        }
      }
      if (this.index == 2) {
        if (this.motivation_to_work != "") {
          this.index++;
          this.swiper.slideTo(this.index);
        }
      }
      if (this.index > 2) {
        const formData = {
          name: values.name,
          email: values.email,
          phone: this.FullNumber,
          latitude: '4455',
          longitude: '8855',
          city_id: this.city,
          educational_qualification: values.educational_qualification,
          motivation_to_work: values.motivation_to_work,
          previous_projects: values.previous_projects,
          existing_franchises: values.existing_franchises,
          experience_in_food_beverage: values.experience_in_food_beverage,
          investment_amount: values.investment_amount,
        };
        if (this.Status) {
          document.getElementById("but-submit").setAttribute("disabled", "");
          await axios
            .post(`user/franchise`, formData, {
              headers: {
                Authorization: `Bearer ${cookie.get("Token")}`,
              },
            })
            .then((res) => {
              if (res.status == 200) {
                notify({
                  type: "success",
                  title: this.$i18n.locale === 'ar' ? 'تسجيل الطلب' : 'Submitting request',
                  text: this.$i18n.locale === 'ar' ? 'تم تسجيل طلبك بنجاح' : 'Your request has been successfully submitted.',
                });
                this.$store.commit("SET_JoinUsData", res.data.data);
              }
              this.$router.push("/");
            })
            .catch(function (error) {
              notify({
                type: "error",
                title: this.$i18n.locale === 'ar' ? "خـطـأ!!" : "Error!!",
                text:  this.$i18n.locale === 'ar' ? "حدث خطأ أثناء تسجيل طلبك" : "An error occurred while submitting your request.",
              });
            });
        }
        this.Status = true;
      }
    },
  },

  mounted() {
    const output = document.querySelector("#output");
    const inputElement = document.querySelector("#phone");

    const iti = intlTelInput(inputElement, {
      i18n: {
        searchPlaceholder: this.$i18n.locale == "ar" ? "بحث" : "Search",
      },
      initialCountry: "auto",
      nationalMode: true,
      geoIpLookup: callback => {
        fetch("https://ipapi.co/country")
          .then(res => {
            if (!res.ok) {
      throw new Error('Network response was not ok');
    }
    return res.text();
          })
          .then(data => callback(data))
          .catch(() => callback("us"));

      },
      utilsScript:
        "https://cdn.jsdelivr.net/npm/intl-tel-input@23.7.3/build/js/utils.js",
    });

    const handleChange = () => {
      let text;
      if (this.$i18n.locale == "ar") {
        if (inputElement.value != "") {
          text = iti.isValidNumber()
            ? ""
            : "رقم غير صحيح - الرجاء إعادة المحاولة";
          if (iti.isValidNumber()) {
            this.FullNumber = iti.getNumber();
          }
        } else {
          text = "";
        }
      } else {
        if (inputElement.value != "") {
          text = iti.isValidNumber() ? "" : "Invalid number - please try again";
          if (iti.isValidNumber()) {
            this.FullNumber = iti.getNumber();
          } else {
            this.FullNumber = false;
          }
        } else {
          text = "Please enter a valid number below";
        }
      }
      const textNode = document.createTextNode(text);
      output.innerHTML = "";
      output.appendChild(textNode);
    };

    // listen to "keyup", but also "change" to update when the user selects a country
    inputElement.addEventListener("change", handleChange);
    inputElement.addEventListener("keyup", handleChange);
      this.$store.dispatch('getCities').then(r => {
            this.cities = this.$store.state.cities;
        });
  },
  watch: {
    index(newindex, oldindex) {
      if (newindex < 2) {
        this.Status = false;
      }
    },
  },
};
</script>
